import { inject } from '@angular/core'
import { ResolveFn, Router } from '@angular/router'
import { AuthService } from './auth.service'

export const authResolverResolver: ResolveFn<any> = (route, state) => {
  const authService = inject(AuthService)
  const router = inject(Router)
  authService._loggingOut.subscribe((value) => {
    if (value) {
      router.navigate['/auth/login']
    } else {
      if (!authService.tokenExpired() || authService.isLoggedIn() ) {
        router.navigate(['/pages/dashboard', false])
      } else {
        router.navigate['/auth/login']
      }
    }
  })
}
