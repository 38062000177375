import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, catchError, finalize, map } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { jwtDecode } from "jwt-decode";
import { MatDialog } from "@angular/material/dialog";
import { resetStore } from "src/app/app.store";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });
  baseUrlAuth = environment.backend.baseUrlIam;
  private serviceSignUp = "/oauth2/sign_up";
  private serviceSignIn = "/oauth2/sign_in";
  private serviceSignOut = "/oauth2/sign_out";
  private serviceSocialSignIn = "/oauth2/social/sign_in";
  private serviceValidateLogin = "/oauth2/validate";
  _loggingOut = new BehaviorSubject(false);
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  sessionOut = new BehaviorSubject(false);
  helper = new JwtHelperService();
  currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  currentRoute:any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog
  ) {
     this.currentRoute = router.routerState.snapshot.url;
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const token = this.getToken();
    const decodedUser = token ? this.decodeToken(token) : null;
    this.currentUserSubject = new BehaviorSubject<any>(decodedUser);
    this.currentUser = this.currentUserSubject.asObservable();
  }
  login(body: object) {
    return this.http.post(`${this.baseUrlAuth}` + this.serviceSignIn, body, {
      headers: this.headers,
    });
  }

  validateLogin(body: Object) {
    return this.http.post(
      `${this.baseUrlAuth}` + this.serviceValidateLogin,
      body,
      {
        headers: this.headers,
      }
    );
  }
  register(user: object) {
    return this.http.post(`${this.baseUrlAuth}` + this.serviceSignUp, user, {
      headers: this.headers,
    });
  }
  getToken() {
    const cookieValue = this.cookieService.get("access_token");
    return cookieValue ? cookieValue : "";
  }
  getGeneric(url: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.getToken(),
    });
    return this.http.get(`${this.baseUrlAuth}` + url, {
      headers: headers,
    });
  }
  getRefreshToken() {
    const cookieValue = this.cookieService.get("refresh_token");
    return cookieValue ? cookieValue : "";
  }
    isLoggedIn(): boolean {
    const token: String = this.getToken();
    const refreshToken = this.getRefreshToken();
    return token && token.length > 0 ? true : false;
  }
  logout(loggingOut?) {
    const applicationId =
        JSON.stringify(this.getUserPermissions()) === JSON.stringify(['super_admin'])
            ? 'accountic_admin_app'
            : 'accountic_accountant_app';
    if (!this.getToken()) {
    } else {
      if (this.tokenExpired()) {
        this.sessionOut.next(true);
      } else {
        this.sessionOut.next(false);
      }
      loggingOut
        ? this._loggingOut.next(loggingOut?.getValue())
        : this._loggingOut.next(false);
      return this.http
        .post(
          `${this.baseUrlAuth}` + this.serviceSignOut,
          {
            application_id: applicationId,
            refresh_token: this.getRefreshToken(),
          },
          {
            headers: this.headers,
          }
        )
        .pipe(
          map(() => {
            this.router.navigate(["/auth/login"]);
            this.cookieService.delete("access_token", "/");
            this.cookieService.delete("refresh_token", "/");
            localStorage.clear();
            this.dialog.closeAll();
            this.currentUserSubject.next(null);
            setTimeout(() => {
              resetStore();
            }, 2500);
            return true;
          }),
          catchError((): any => {
            this.cookieService.delete("access_token", "/");
            this.cookieService.delete("refresh_token", "/");
            this.dialog.closeAll();
            this.router.navigate(["/auth/login"]);
            return false;
          })
        )
        .subscribe();
    }
  }
  tokenExpired() {
    let token = this.getToken();
    return this.helper.isTokenExpired(token);
  }
  getCurrentUser() {
    const token = this.cookieService.get("access_token");
    if (token) {
      const user = jwtDecode(token);
      return user;
    }
  }
  getUserPermissions() {
    const token = this.cookieService.get("access_token");
    if (token) {
      const Permissions = jwtDecode(token)["applications"]?.flatMap(app => app["permissions"]) || [];
      return Permissions;
    }
  }
  decodeToken(token) {
    return jwtDecode(token);
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  putGeneric(url: string, object: any): Observable<any> {
    return this.http.put(`${this.baseUrlAuth}${url}`, object);
  }
}
