import { Injectable } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable, map, BehaviorSubject} from "rxjs";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import {
  extractSegment,
  extractValuesFromJson,
} from "../utilities/decodeCloudfrontToken";

@Injectable({
  providedIn: "root",
})
export class GenericService {
  baseUrl = environment.backend.baseUrlAccountic;
  baseUrlAdapters = environment.backend.baseUrlAdapters;
  baseUrlAuth = environment.backend.baseUrlIam;

  cloudPolicy: string;
  cloudSignature: string;
  cloudKeyPairId: string;
  baseUrlUploadImage: any;

  private shopIntegratedSubject = new BehaviorSubject<boolean>(false);
  shopIntegrated$ = this.shopIntegratedSubject.asObservable();
  private unreviewedCountSubject = new BehaviorSubject<number>(0);
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private cookieService: CookieService
  ) {}
  getGenericById(url: string, id?: number): Observable<any> {
    return this.http.get(`${this.baseUrl}` + url + `/${id}`);
  }
  get unreviewedCount$(): Observable<number> {
    return this.unreviewedCountSubject.asObservable();
  }
  setUnreviewedCount(count: number): void {
    this.unreviewedCountSubject.next(count);
  }
  setShopIntegrated(status: boolean): void {
    this.shopIntegratedSubject.next(status);
  }
    getGeneric(url: string, token?: string): Observable<any> {
    let headers = new HttpHeaders();
    if (token && !this.cookieService.check("access_token")) {
      headers = headers.set("Authorization", `Bearer ${token}`);
    }
    return this.http.get(`${this.baseUrl}${url}`, { headers });
  }
  putGeneric(url: string, object: any): Observable<any> {
    return this.http.put(`${this.baseUrl}${url}`, object);
  }
  getInvoiceGeneric(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: "blob",
      withCredentials: true,
    });
  }
  putCloudFront(file: File, fileName): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "text/plain",
    }).append(
      "Cookie",
      `CloudFront-Policy=${this.cookieService.get(
        "CloudFront-Policy"
      )};CoudFront-Signature=${this.cookieService.get(
        "CloudFront-Signature"
      )};CloudFront-Key-Pair-Id=${this.cookieService.get(
        "CloudFront-Key-Pair-Id"
      )}`
    );
    return this.http.put(
      `${extractValuesFromJson(this.cookieService.get("CloudFront-Policy"))}` +
        fileName,
      file,
      {
        headers: headers,
        withCredentials: true,
      }
    );
  }
  postGeneric(url: string, object?: any, token?) {
    let headers = new HttpHeaders();
    if (token && !this.cookieService.check("access_token")) {
      headers = headers.set("Authorization", `Bearer ${token}`);
    }
    return this.http.post(`${this.baseUrl}` + url, object, { headers });
  }
  postUsersGeneric(url: string, object?: any) {
    return this.http.post(`${this.baseUrlAuth}` + url, object);
  }
 getUsersGeneric(url: string, object?: any) {
    return this.http.get(`${this.baseUrlAuth}` + url);
  }
  deleteGeneric(url: any, object?: any): Observable<any> {
    return this.http.delete(`${this.baseUrl}` + url, {
      body: object,
    });
  }
  uploadImages(url: any, object?: any): Observable<any> {
    const headers = new HttpHeaders().append(
      "Cookies",
      `CloudFront-Policy=${this.cookieService.get(
        "CloudFront-Policy"
      )};CoudFront-Signature=${this.cookieService.get(
        "CloudFront-Signature"
      )};CloudFront-Key-Pair-Id=${this.cookieService.get(
        "CloudFront-Key-Pair-Id"
      )}`
    );
    return this.http.post(`${this.baseUrl}` + url, object, {
      headers: headers,
    });
  }
  patchGeneric(url: string, object?: any, token?: string): Observable<any> {
    let headers = new HttpHeaders();

    if (token && !this.cookieService.check("access_token")) {
      headers = headers.set("Authorization", `Bearer ${token}`);
    }

    return this.http.patch(`${this.baseUrl}${url}`, object, { headers });
  }

  downloadInvoice(url: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}${url}`, {
      responseType: "blob",
    });
  }
  getAdapters(url: string): Observable<any> {
    return this.http.get(`${this.baseUrlAdapters}${url}`);
  }
  postAdapters(url: string, object?: any): Observable<any> {
    return this.http.post(`${this.baseUrlAdapters}${url}`, object);
  }
  putAdapters(url: string, object?: any): Observable<any> {
    return this.http.put(`${this.baseUrlAdapters}${url}`, object);
  }
}
