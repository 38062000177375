import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CopyrightComponent } from "./features/dashboard/copyright/copyright.component";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HttpRequestInterceptor } from "./core/interceptors/auth.interceptor.interceptor";
import { AppRoutingModule } from "./app-routing.module";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "@abacritt/angularx-social-login";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "./shared/services/auth/auth.service";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { CommonModule } from "@angular/common";
import { GenericService } from "./shared/services/generic.service";
import {
  DevToolsExtension,
  NgRedux,
  NgReduxModule,
} from "@angular-redux-ivy/store";
import { createLogger } from "redux-logger";
import { IAppState, INITIAL_STATE } from "./app.reducer";
import { MatDialogModule } from "@angular/material/dialog";
import { store } from "./app.store";
import { CloudFrontService } from "./shared/services/cloud-front-service.service";
import {
  provideNgxWebstorage,
  withLocalStorage,
  withSessionStorage,
} from "ngx-webstorage";
import { MAT_DATE_LOCALE } from "@angular/material/core";
@NgModule({
  declarations: [AppComponent, CopyrightComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    MatSelectCountryModule.forRoot("en"),
    ToastrModule.forRoot(),
    SocialLoginModule,
    NgReduxModule,
    MatDialogModule,
  ],
  providers: [
    CookieService,
    AuthService,
    GenericService,
    CloudFrontService,
    ToastrService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    provideNgxWebstorage(withLocalStorage(), withSessionStorage()),
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>, dev: DevToolsExtension) {
    const logger = isDevMode() ? [createLogger()] : [];
    ngRedux.provideStore(store);
  }
}
